// PageCard.js
import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const PageCard = ({ title, to }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Button component={Link} to={to} variant="outlined" color="primary">
          Go to {title}
        </Button>
      </CardContent>
    </Card>
  );
};

export default PageCard;
