// RefundPolicyUpdate.js
import React, { useContext, useEffect, useState } from "react";
import { Container, TextField, Button } from "@mui/material";
import { MainContext } from "../Context/MainContext";
import { toast } from "react-toastify";

const RefundPolicyUpdate = () => {
  const {
    refund,
    setRefund,
    getRefund,
    updateRefund,
    toastifyData,
    checkToken,
  } = useContext(MainContext);

  useEffect(() => {
    checkToken();
    getRefund();
  }, []);

  const handleContentChange = (event) => {
    setRefund({ content: event.target.value });
  };

  const handleUpdate = () => {
    if (refund.content.length < 20) {
      toast.error(
        "Length of content should be greater than 20 characters",
        toastifyData
      );
    } else {
      updateRefund();
    }
  };

  return (
    <Container>
      {refund ? (
        <>
          <h2>Update Refund Policy</h2>
          <TextField
            label="Refund Policy Content"
            multiline
            rows={30}
            fullWidth
            variant="outlined"
            value={refund.content.length > 0 ? refund.content : ""}
            onChange={handleContentChange}
          />
          <div className="spaceY" />
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Update
          </Button>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default RefundPolicyUpdate;
