import { Button, Container, TextField, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MainContext } from "../Context/MainContext";
import { toast } from "react-toastify";

// EditCategory.js
// ... Import statements

const EditCategory = () => {
  const { categoryId } = useParams();
  const {
    category,
    getCategory,
    setCategory,
    updateCategory,
    toastifyData,
    checkToken,
  } = useContext(MainContext);

  useEffect(() => {
    checkToken();
    getCategory(categoryId);
  }, [categoryId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategory({ ...category, [name]: value });
  };

  const handleUpdate = () => {
    const formData = new FormData();
    formData.append("name", category.name);
    formData.append("image", category.image);
    if (category.name.length < 3 && !category.image) {
      toast.error("Name should have more than 3 characters", toastifyData);
      toast.error("Upload Image too", toastifyData);
    } else if (category.name.length < 3) {
      toast.error("Name should have more than 3 characters", toastifyData);
    } else if (!category.image) {
      toast.error("Upload Image too", toastifyData);
    } else {
      updateCategory(categoryId, formData);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCategory({ ...category, image: file });
    }
  };
  return (
    <Container>
      {category ? (
        <>
          <h2>Edit Category</h2>
          <form>
            <img
              src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${category.image.name}`}
              alt="product"
              style={{ width: "100px" }}
            />
            <div className="spaceY" />
            <TextField
              name="name"
              label="Subcategory Name"
              variant="outlined"
              fullWidth
              value={category.name}
              onChange={handleInputChange}
            />
            <div className="spaceY" />
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="image-upload"
              onChange={handleImageChange}
            />
            <label htmlFor="image-upload">
              <Button variant="outlined" component="span">
                Upload Image
              </Button>
            </label>
            <div className="spaceY" />
            <Typography variant="body1" gutterBottom>
              {category.image
                ? `Image: ${
                    category.image.name ? category.image.name : category.image
                  }`
                : "No image selected"}
            </Typography>
            <div className="spaceY" />
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Update
            </Button>
          </form>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default EditCategory;
