import { Container, Paper, Typography, TextField, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MainContext } from "../Context/MainContext";

const Homepage = () => {
  const { login, checkToken } = useContext(MainContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    checkToken();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();

    login(username, password);
  };
  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: "16px" }}>
        <Typography variant="h5">Login</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            autoComplete="off"
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            autoComplete="off"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Homepage;
