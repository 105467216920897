/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { MainContext } from "../Context/MainContext";
import { ToastContainer } from "react-toastify";
import axios from "axios";

function ShippingPrices() {
  const { getShippingPrices, shipping, checkToken } = useContext(MainContext);

  useEffect(() => {
    checkToken();
    getShippingPrices();
  }, []);

  return (
    <>
      {shipping ? (
        <div>
          <h2>Country List</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shipping.map((country) => (
                  <TableRow key={country._id}>
                    <TableCell>{country.name}</TableCell>
                    <TableCell>{country.price}</TableCell>
                    <TableCell>
                      <Link to={`${country._id}`}>Edit</Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ShippingPrices;
