// ProductList.js
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { List, ListItem, ListItemText, Button, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../Context/MainContext";

const ProductList = () => {
  const navigate = useNavigate();

  const { getProducts, products, deleteProduct, checkToken } =
    useContext(MainContext);

  useEffect(() => {
    checkToken();
    getProducts();
  }, []);

  const handleDelete = (id) => {
    deleteProduct(id);
  };
  const handleEdit = (id) => {
    navigate(`/products/${id}`);
  };

  return (
    <Container>
      <h2>Product List</h2>
      <Link to="/products/add-product">Add Product</Link>
      {products.length > 0 ? (
        <List>
          {products.map((product) => (
            <ListItem key={product._id}>
              <img
                src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${product.images[0]}`}
                alt="product"
                style={{ width: "100px" }}
              />
              <div className="spaceX" />
              <ListItemText
                primary={product.title}
                secondary={`Price: ${product.price}`}
              />
              <div className="spaceX" />

              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleEdit(product._id)}
              >
                Edit
              </Button>
              <div className="spaceX" />

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleDelete(product._id)}
              >
                Delete
              </Button>
            </ListItem>
          ))}
        </List>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ProductList;
