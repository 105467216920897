import { Button, Container, List, ListItem, ListItemText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../Context/MainContext";

const SubcategoryList = () => {
  const navigate = useNavigate();
  const { getSubcategories, subcategories, deleteSubcategories, checkToken } =
    useContext(MainContext);

  useEffect(() => {
    checkToken();
    getSubcategories();
  }, []);

  const handleDelete = (id) => {
    deleteSubcategories(id);
  };
  const handleEdit = (id) => {
    navigate(`/subcategories/${id}`);
  };

  return (
    <>
      {subcategories ? (
        <Container>
          <h2>Subcategory List</h2>
          <Link to="add-subcategory">Add Subcategory</Link>
          <List>
            {subcategories
              .filter((sub) => sub._id !== "6519f041bc02339870230108")
              .map((subcategory) => (
                <ListItem key={subcategory._id}>
                  <img
                    src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${subcategory.image}`}
                    alt="product"
                    style={{ width: "100px" }}
                  />{" "}
                  <div className="spaceX" />
                  <ListItemText primary={subcategory.name} />{" "}
                  <div className="spaceX" />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEdit(subcategory._id)}
                  >
                    Edit
                  </Button>{" "}
                  <div className="spaceX" />
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(subcategory._id)}
                  >
                    Delete
                  </Button>
                </ListItem>
              ))}
          </List>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default SubcategoryList;
