import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Switch,
} from "@mui/material";
import { MainContext } from "../Context/MainContext";

const AddProduct = () => {
  const {
    categories,
    getCategories,
    subcategories,
    getSubcategories,
    addProduct,
    checkToken,
  } = useContext(MainContext);
  const [formData, setFormData] = useState({
    images: [],
    title: "",
    price: "",
    show: true,
    dimensions: "",
    description: "",
    category: "",
    subcategory: "6519f041bc02339870230108",
  });

  useEffect(() => {
    checkToken();
    getCategories();
    getSubcategories();
  }, []);
  useEffect(() => {
    setFormData({
      ...formData,
      category: categories.length > 0 ? categories[0]._id : "",
      subcategory: "6519f041bc02339870230108",
    });
  }, [categories, subcategories]);
  // Define state variables for the form fields

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    const { files } = e.target;
    // Convert the FileList to an array of file names
    const imageFiles = Array.from(files);
    setFormData({ ...formData, images: imageFiles });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata = new FormData();

    for (var i = 0; i < formData.images.length; i++) {
      formdata.append(`images`, formData.images[i]);
    }
    formdata.append("title", formData.title);
    formdata.append("price", formData.price);
    formdata.append("show", formData.show);
    formdata.append("dimensions", formData.dimensions);
    formdata.append("description", formData.description);
    formdata.append("category", formData.category);
    formdata.append("subcategory", formData.subcategory);

    addProduct(formdata);
  };
  const handleCategoryChange = (e) => {
    setFormData({ ...formData, category: e.target.value });
  };
  const handleSubcategory = (e) => {
    setFormData({ ...formData, subcategory: e.target.value });
  };

  const handleShowChange = () => {
    setFormData({ ...formData, show: !formData.show });
  };

  return (
    <div>
      <h2>Add Product</h2>
      <form onSubmit={handleSubmit}>
        {/* Add form fields for each property in the schema */}
        <FormControl fullWidth>
          <Input
            type="file"
            name="images"
            inputProps={{ multiple: true }}
            onChange={handleFileChange}
            accept="image/*" // Specify the accepted file types (e.g., images)
          />
          <div className="spaceY" />

          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <div className="spaceY" />
          <TextField
            label="Price"
            name="price"
            type="number"
            value={formData.price}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <div className="spaceY" />
          <FormControl fullWidth>
            <InputLabel shrink={true}>Show</InputLabel>
            <Switch
              name="show"
              checked={formData.show}
              onChange={handleShowChange}
            />
          </FormControl>
          <div className="spaceY" />
          <TextField
            label="Dimensions"
            name="dimensions"
            value={formData.dimensions}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <div className="spaceY" />
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            multiline
            onChange={handleInputChange}
            fullWidth
            required
          />
          <div className="spaceY" />
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              label="Category"
              name="category"
              value={formData.category}
              onChange={handleCategoryChange}
            >
              {categories.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="spaceY" />
          <FormControl fullWidth>
            <InputLabel>Subcategory</InputLabel>
            <Select
              label="Subcategory"
              name="subcategory"
              value={formData.subcategory}
              onChange={handleSubcategory}
            >
              {subcategories.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="spaceY" />
          <Button type="submit" variant="contained" color="primary">
            Add Product
          </Button>
        </FormControl>
      </form>
    </div>
  );
};

export default AddProduct;
