import {
  Button,
  Container,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { MainContext } from "../Context/MainContext";

const CreateSubcategory = () => {
  const {
    toastifyData,
    createSubcategories,
    checkToken,
    categories,
    getCategories,
  } = useContext(MainContext);
  useEffect(() => {
    checkToken();
    getCategories();
  }, []);
  const [subcategory, setSubcategory] = useState({
    name: "",
    image: "",
    category: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSubcategory({ ...subcategory, [name]: value });
  };

  const handleCreate = () => {
    const formData = new FormData();
    formData.append("name", subcategory.name);
    formData.append("category", subcategory.category);

    formData.append("image", subcategory.image);

    if (
      subcategory.name.length < 3 &&
      !subcategory.image &&
      subcategory.category === ""
    ) {
      toast.error("Name should have more than 3 characters", toastifyData);
      toast.error("You should choose category too", toastifyData);
      toast.error("Upload Image too", toastifyData);
    } else if (subcategory.name.length < 3 && subcategory.category === "") {
      toast.error("Name should have more than 3 characters", toastifyData);
      toast.error("You should choose category too", toastifyData);
    } else if (!subcategory.image) {
      toast.error("Upload Image too", toastifyData);
    } else if (subcategory.category === "") {
      toast.error("You should choose category too", toastifyData);
    } else {
      createSubcategories(formData);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSubcategory({ ...subcategory, image: file });
    }
  };
  return (
    <Container>
      <h2>Creat New Subcategory</h2>
      <form>
        <TextField
          name="name"
          label="Subcategory Name"
          variant="outlined"
          fullWidth
          value={subcategory.name}
          onChange={handleInputChange}
        />
        <div className="spaceY" />
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="image-upload"
          onChange={handleImageChange}
        />
        <label htmlFor="image-upload">
          <Button variant="outlined" component="span">
            Upload Image
          </Button>
        </label>
        <div className="spaceY" />
        <Typography variant="body1" gutterBottom>
          {subcategory.image
            ? `Image: ${
                subcategory.image.name
                  ? subcategory.image.name
                  : subcategory.image
              }`
            : "No image selected"}
        </Typography>
        <div className="spaceY" />
        <div className="spaceY" />
        <FormControl fullWidth>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            name="category"
            value={subcategory.category}
            onChange={(e) => {
              setSubcategory({ ...subcategory, category: e.target.value });
            }}
          >
            {categories.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="spaceY" />
        <Button variant="contained" color="primary" onClick={handleCreate}>
          Add
        </Button>
      </form>
    </Container>
  );
};

export default CreateSubcategory;
