// PrivacyPolicyUpdate.js
import React, { useContext, useEffect, useState } from "react";
import { Container, TextField, Button } from "@mui/material";
import { MainContext } from "../Context/MainContext";
import { toast } from "react-toastify";

const PrivacyPolicyUpdate = () => {
  const {
    privacy,
    setPrivacy,
    getPrivacy,
    toastifyData,
    updatePrivacy,
    checkToken,
  } = useContext(MainContext);

  useEffect(() => {
    checkToken();
    getPrivacy();
  }, []);

  const handleContentChange = (event) => {
    setPrivacy({ content: event.target.value });
  };

  const handleUpdate = () => {
    if (privacy.content.length < 20) {
      toast.error(
        "Length of content should be greater than 20 characters",
        toastifyData
      );
    } else {
      updatePrivacy();
    }
  };

  return (
    <Container>
      {privacy ? (
        <>
          <h2>Update Privacy Policy</h2>
          <TextField
            label="Privacy Policy Content"
            multiline
            rows={30}
            fullWidth
            variant="outlined"
            value={privacy.content.length > 0 ? privacy.content : ""}
            onChange={handleContentChange}
          />
          <div className="spaceY" />
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Update
          </Button>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default PrivacyPolicyUpdate;
