import React, { createContext, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const MainContext = createContext();

const MainProvider = ({ children }) => {
  const navigate = useNavigate();

  const [shipping, setShipping] = useState();
  const [country, setCountry] = useState();
  const [terms, setTerms] = useState({
    content: ``,
  });
  const [refund, setRefund] = useState({
    content: ``,
  });
  const [privacy, setPrivacy] = useState({
    content: ``,
  });
  const [aboutUs, setAboutUs] = useState({ description: ``, image: `` });
  const [subcategories, setSubcategories] = useState([]);
  const [subcategory, setSubcategory] = useState({ name: ``, image: `` });

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({ name: ``, image: `` });

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    images: [],
    title: ``,
    price: ``,
    show: true,
    dimensions: ``,
    description: ``,
    category: ``,
    subcategory: ``,
  });

  const [homepageData, setHomepageData] = useState({
    sliders: [],
    secondBanner: {
      image: ``,
      header: ``,
      description: ``,
    },
    thirdBanner: {
      header: ``,
      paragraph: ``,
      image: ``,
    },
  });

  const [homepageSliders, setHomepageSliders] = useState([]);
  const [homepageBanner1, setHomepageBanner1] = useState([]);
  const [homepageBanner2, setHomepageBanner2] = useState([]);

  const toastifyData = {
    position: `top-right`,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,

    theme: `dark`,
  };

  const checkToken = async () => {
    if (localStorage.getItem(`token`) === null) {
      navigate(`/`);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_PRODUCTION_API_URL}verifyToken`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem(`token`),
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          toast.error(`Please Login first`, toastifyData);

          navigate(`/`);
        });
    }
  };

  const login = async (username, password) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTION_API_URL}users/login`, {
        username: username,
        password: password,
      })
      .then((response) => {
        localStorage.setItem(`token`, response.data.token);
        // localStorage.setItem(`login`, true);
        navigate(`/main`);
        toast.success(`You are loged in`, toastifyData);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        toast.error(error.response.data.error, toastifyData);
        localStorage.setItem(`login`, true);
      });
  };

  const getShippingPrices = async () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}country`)
      .then((res) => {
        setShipping(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShippingById = async (id) => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}country/${id}`, {
        headers: {
          Authorization: localStorage.getItem(`token`),
        },
      })
      .then((res) => {
        setCountry({
          name: res.data.name,
          price: res.data.price,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const updateShippingById = async (id) => {
    axios
      .put(
        `${process.env.REACT_APP_PRODUCTION_API_URL}country/${id}`,
        country,
        {
          headers: {
            Authorization: localStorage.getItem(`token`),
          },
        }
      )
      .then((res) => {
        toast.success(`Shipping Price Updated Successfully!`);
        navigate(`/shipping-prices`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };

  const getTerms = async () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}terms`)
      .then((res) => {
        console.log(res.data.content);
        setTerms({ content: res.data.content });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };

  const updateTerms = async () => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTION_API_URL}terms`, terms, {
        headers: {
          Authorization: localStorage.getItem(`token`),
        },
      })
      .then((res) => {
        toast.success(`Terms of Services Updated Successfully!`, toastifyData);
        navigate(`/main`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };

  const getRefund = async () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}refund`)
      .then((res) => {
        console.log(res.data.content);

        setRefund({ content: res.data.content });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const updateRefund = async () => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTION_API_URL}refund`, refund, {
        headers: {
          Authorization: localStorage.getItem(`token`),
        },
      })
      .then((res) => {
        toast.success(`Refund Policy Updated Successfully!`, toastifyData);
        navigate(`/main`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const getPrivacy = async () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}privacy`)
      .then((res) => {
        console.log(res.data.content);

        setPrivacy({ content: res.data.content });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const updatePrivacy = async () => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTION_API_URL}privacy`, privacy, {
        headers: {
          Authorization: localStorage.getItem(`token`),
        },
      })
      .then((res) => {
        toast.success(`Privacy Policy Updated Successfully!`, toastifyData);
        navigate(`/main`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };

  const getAbout = async () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}about`)
      .then((res) => {
        setAboutUs({
          description: res.data.description,
          image: res.data.image,
        });
        getAboutImage(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };

  const updateAbout = async (formData) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTION_API_URL}about`, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(`About Us Updated Successfully!`, toastifyData);
        navigate(`/main`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error, toastifyData);
      });
  };
  const getAboutImage = async (res) => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_UPLOADS}${res.data.image}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: `image/png` });

        // Create a File object with additional properties
        const fileName = res.data.image; // Set the desired file name
        const mimeType = `image/png`; // Set the MIME type
        const lastModified = Date.now(); // Set the last modified timestamp
        const size = blob.size; // Get the blob size
        const type = mimeType; // Set the type to match the MIME type
        const webkitRelativePath = ``; // Set the webkitRelativePath (optional)

        const file = new File([blob], fileName, {
          lastModified,
          type,
          size,
          webkitRelativePath,
        });

        setAboutUs({
          ...aboutUs,
          description: res.data.description,
          image: file,
        });
      })
      .catch((error) => {
        console.error(`Error fetching the image:`, error);
      });
  };

  const getSubcategories = async () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}subcategory`)
      .then((res) => {
        setSubcategories(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const createSubcategories = async (formData) => {
    axios
      .post(
        `${process.env.REACT_APP_PRODUCTION_API_URL}subcategory`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(
          `Subcategory ${res.data.name} Created Successfully!`,
          toastifyData
        );
        navigate(`/subcategories`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const deleteSubcategories = async (id) => {
    axios
      .delete(`${process.env.REACT_APP_PRODUCTION_API_URL}subcategory/${id}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(`Subcategory Deleted Successfully!`, toastifyData);
        setSubcategories(subcategories.filter((item) => item._id !== id));
        navigate(`/subcategories`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const getSubcategory = async (id) => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}subcategory/${id}`)
      .then((res) => {
        setSubcategory(res.data);
        getImages(res, subcategory, setSubcategory, false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };

  const getImages = async (res, state, setState, isCategory) => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_UPLOADS}${res.data.image}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: "image/png" });

        // Create a File object with additional properties
        const fileName = res.data.image; // Set the desired file name
        const mimeType = `image/png`; // Set the MIME type
        const lastModified = Date.now(); // Set the last modified timestamp
        const size = blob.size; // Get the blob size
        const type = mimeType; // Set the type to match the MIME type
        const webkitRelativePath = ``; // Set the webkitRelativePath (optional)

        const file = new File([blob], fileName, {
          lastModified,
          type,
          size,
          webkitRelativePath,
        });

        if (isCategory) {
          setState({
            ...state,
            name: res.data.name,
            image: file,
          });
        } else {
          setState({
            ...state,
            image: file,
            name: res.data.name,
            category: res.data.category,
          });
        }
      })
      .catch((error) => {
        console.error(`Error fetching the image:`, error);
      });
  };

  const updateSubcategory = async (id, formData) => {
    axios
      .put(
        `${process.env.REACT_APP_PRODUCTION_API_URL}subcategory/${id}`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(
          `Subcategory ${res.data.name} Updated Successfully!`,
          toastifyData
        );
        navigate(`/subcategories`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error, toastifyData);
      });
  };
  const getCategories = async () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}categories`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const createCategories = async (formData) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTION_API_URL}categories`, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(
          `Category ${res.data.name} Created Successfully!`,
          toastifyData
        );
        navigate(`/categories`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const deleteCategories = async (id) => {
    axios
      .delete(`${process.env.REACT_APP_PRODUCTION_API_URL}categories/${id}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(`Category Deleted Successfully!`, toastifyData);
        setCategories(categories.filter((item) => item._id !== id));
        navigate(`/categories`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const getCategory = async (id) => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}categories/${id}`)
      .then((res) => {
        setCategory(res.data);
        getImages(res, category, setCategory, true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };

  const updateCategory = async (id, formData) => {
    axios
      .put(
        `${process.env.REACT_APP_PRODUCTION_API_URL}categories/${id}`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(
          `Category ${res.data.name} Updated Successfully!`,
          toastifyData
        );
        navigate(`/categories`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error, toastifyData);
      });
  };

  const getProducts = async () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}products`)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const deleteProduct = async (id) => {
    axios
      .delete(`${process.env.REACT_APP_PRODUCTION_API_URL}products/${id}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setProducts(products.filter((item) => item._id !== id));
        toast.success(`Product Deleted Successfully!`, toastifyData);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        toast.error(err.response.data.error, toastifyData);
      });
  };
  const addProduct = async (formdata) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTION_API_URL}products/`, formdata, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(`Product Created Successfully!`, toastifyData);
        navigate(`/products`);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        toast.error(err.response.data.error, toastifyData);
      });
  };
  const getProduct = async (id) => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}products/${id}`)
      .then((res) => {
        setProduct({
          title: res.data.title,
          price: res.data.price,
          images: res.data.images,
          show: res.data.show,
          dimensions: res.data.dimensions,
          description: res.data.description,
          category: res.data.category._id,
          subcategory: res.data.subcategory._id,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message, toastifyData);
      });
  };
  const updateProduct = async (formdata, id) => {
    axios
      .put(
        `${process.env.REACT_APP_PRODUCTION_API_URL}products/${id}`,
        formdata,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(`Product Updated Successfully!`, toastifyData);
        navigate(`/products`);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        toast.error(err.response.data.error, toastifyData);
      });
  };

  const updateHomepage = async (formData) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCTION_API_URL}homepage`, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(`Homepage Updated Successfully!`, toastifyData);
      })
      .catch((err) => {
        toast.error(err.response.data.error, toastifyData);
      });
  };

  const getSliders = async (resArray) => {
    // Accept an array of image names
    try {
      const fetchPromises = resArray.map(async (res) => {
        const response = await axios.get(
          `${process.env.REACT_APP_PRODUCTION_UPLOADS}homepage/${res}`,
          {
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([response.data], { type: `image/png` });
        const fileName = res;
        const mimeType = `image/png`;
        const lastModified = Date.now();
        const size = blob.size;
        const type = mimeType;
        const webkitRelativePath = ``;

        const file = new File([blob], fileName, {
          lastModified,
          type,
          size,
          webkitRelativePath,
        });

        return file;
      });

      const fetchedImages = await Promise.all(fetchPromises);

      const updatedImages = [...fetchedImages];

      setHomepageSliders(updatedImages);
    } catch (error) {
      console.error(`Error fetching images:`, error);
    }
  };

  const getHomepageBanners = async (res, state, setState) => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_UPLOADS}homepage/${res}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: `image/png` });

        // Create a File object with additional properties
        const fileName = res; // Set the desired file name
        const mimeType = `image/png`; // Set the MIME type
        const lastModified = Date.now(); // Set the last modified timestamp
        const size = blob.size; // Get the blob size
        const type = mimeType; // Set the type to match the MIME type
        const webkitRelativePath = ``; // Set the webkitRelativePath (optional)

        const file = new File([blob], fileName, {
          lastModified,
          type,
          size,
          webkitRelativePath,
        });

        setState(file);
      })
      .catch((error) => {
        console.error(`Error fetching the image:`, error);
      });
  };
  const getHomepage = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}homepage`)
      .then((res) => {
        setHomepageData({
          ...homepageData,
          secondBanner: res.data.secondBanner,
          thirdBanner: res.data.thirdBanner,
        });

        getSliders(res.data.sliders);

        getHomepageBanners(
          res.data.secondBanner.image,
          homepageBanner1,
          setHomepageBanner1
        );
        getHomepageBanners(
          res.data.thirdBanner.image,
          homepageBanner2,
          setHomepageBanner2
        );
      })
      .catch((err) => {
        toast.error(err.response.data.error, toastifyData);
      });
  };
  return (
    <MainContext.Provider
      value={{
        login,
        checkToken,
        getShippingPrices,
        shipping,
        getShippingById,
        country,
        setCountry,
        updateShippingById,
        getTerms,
        terms,
        setTerms,
        updateTerms,
        refund,
        setRefund,
        getRefund,
        updateRefund,
        toastifyData,
        getPrivacy,
        updatePrivacy,
        privacy,
        setPrivacy,
        aboutUs,
        setAboutUs,
        getAbout,
        updateAbout,
        getAboutImage,
        subcategories,
        getSubcategories,
        createSubcategories,
        deleteSubcategories,
        getSubcategory,
        subcategory,
        setSubcategory,
        updateSubcategory,
        getCategories,
        categories,
        setCategories,
        createCategories,
        deleteCategories,
        getCategory,
        category,
        setCategory,
        updateCategory,
        getProducts,
        products,
        setProducts,
        deleteProduct,
        addProduct,
        product,
        setProduct,
        getProduct,
        updateProduct,
        updateHomepage,
        homepageData,
        setHomepageData,
        getHomepage,
        homepageSliders,
        homepageBanner1,
        homepageBanner2,
        setHomepageSliders,
      }}
    >
      <ToastContainer />

      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainProvider };
