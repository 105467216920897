import React, { useContext, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Switch,
  Paper,
} from "@mui/material";
import { MainContext } from "../Context/MainContext";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const AddProduct = () => {
  const { productId } = useParams();
  const {
    categories,
    getCategories,
    subcategories,
    getSubcategories,
    product,
    setProduct,
    getProduct,
    updateProduct,
    checkToken,
  } = useContext(MainContext);

  useEffect(() => {
    checkToken();
    getCategories();
    getSubcategories();
  }, []);

  useEffect(() => {
    getProduct(productId);
  }, [productId]);
  // Define state variables for the form fields

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    const { files } = e.target;
    // Convert the FileList to an array of file names
    const imageFiles = Array.from(files);
    setProduct({ ...product, images: imageFiles });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata = new FormData();

    for (var i = 0; i < product.images.length; i++) {
      formdata.append(`images`, product.images[i]);
    }
    formdata.append("title", product.title);
    formdata.append("price", product.price);
    formdata.append("show", product.show);
    formdata.append("dimensions", product.dimensions);
    formdata.append("description", product.description);
    formdata.append("category", product.category);
    formdata.append("subcategory", product.subcategory);
    updateProduct(formdata, productId);
  };
  const handleCategoryChange = (e) => {
    setProduct({ ...product, category: e.target.value });
  };
  const handleSubcategory = (e) => {
    setProduct({ ...product, subcategory: e.target.value });
  };

  const handleShowChange = () => {
    setProduct({ ...product, show: !product.show });
  };

  return (
    <div>
      <h2>Edit Product</h2>
      <form onSubmit={handleSubmit}>
        <Carousel showThumbs={false}>
          {product.images.map((image, index) => (
            <Paper key={index} style={{ backgroundColor: "black" }}>
              <img
                src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${image}`}
                alt={`${index + 1}`}
                style={{ width: "100px" }}
              />
            </Paper>
          ))}
        </Carousel>
        <div className="spaceY" />
        <FormControl fullWidth>
          <Input
            type="file"
            name="images"
            inputProps={{ multiple: true }}
            onChange={handleFileChange}
            accept="image/*" // Specify the accepted file types (e.g., images)
          />
          <div className="spaceY" />
          <TextField
            label="Title"
            name="title"
            value={product.title}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <div className="spaceY" />
          <TextField
            label="Price"
            name="price"
            type="number"
            value={product.price}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <div className="spaceY" />
          <FormControl fullWidth>
            <InputLabel shrink={true}>Show</InputLabel>
            <Switch
              name="show"
              checked={product.show}
              onChange={handleShowChange}
            />
          </FormControl>
          <div className="spaceY" />
          <TextField
            label="Dimensions"
            name="dimensions"
            value={product.dimensions}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <div className="spaceY" />
          <TextField
            label="Description"
            name="description"
            value={product.description}
            multiline
            onChange={handleInputChange}
            fullWidth
            required
          />
          <div className="spaceY" />
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              label="Category"
              name="category"
              value={product.category}
              onChange={handleCategoryChange}
            >
              {categories.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="spaceY" />
          <FormControl fullWidth>
            <InputLabel>Subcategory</InputLabel>
            <Select
              label="Subcategory"
              name="subcategory"
              value={product.subcategory}
              onChange={handleSubcategory}
            >
              {subcategories.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="spaceY" />
          <Button type="submit" variant="contained" color="primary">
            Edit Product
          </Button>
        </FormControl>
      </form>
    </div>
  );
};

export default AddProduct;
