/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom"; // For URL parameters and navigation
import { Button, TextField } from "@mui/material";
import { MainContext } from "../Context/MainContext";
import { ToastContainer, toast } from "react-toastify";

function ShippingPricesEdit() {
  const { id } = useParams();
  const {
    getShippingById,
    country,
    setCountry,
    updateShippingById,
    checkToken,
  } = useContext(MainContext);

  useEffect(() => {
    checkToken();
    getShippingById(id);
  }, [id]);

  const handleSave = async () => {
    if (Number(country.price) <= 0) {
      toast("Price should be grater than 0", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      updateShippingById(id);
    }
  };

  return (
    <>
      {country ? (
        <div>
          <h2>{id ? "Edit Country" : "Add New Country"}</h2>
          <TextField
            label="Name"
            type="text"
            disabled
            fullWidth
            value={country.name}
            // onChange={(e) => setCountry({ ...country, name: e.target.value })}
          />
          <div className="spaceY" />
          <TextField
            label="Price"
            type="number"
            fullWidth
            value={country.price}
            inputProps={{
              min: 0,
            }}
            onChange={(e) => setCountry({ ...country, price: e.target.value })}
          />
          <div className="spaceY" />
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ShippingPricesEdit;
