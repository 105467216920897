import React, { useContext, useEffect } from "react";
import { MainContext } from "../Context/MainContext";
import { Container, Grid, Paper } from "@mui/material";
import PageCard from "../components/PageCard";
import { ToastContainer } from "react-toastify";

const Main = () => {
  const { checkToken } = useContext(MainContext);
  useEffect(() => {
    checkToken();
  }, []);
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            {" "}
            {/* Add your desired background color */}
            <PageCard title="Home Edit" to="/homepage-editor" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            {" "}
            {/* Add your desired background color */}
            <PageCard title="About Us Edit" to="/aboutus-editor" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            {" "}
            {/* Add your desired background color */}
            <PageCard title="Products" to="/products" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            {" "}
            {/* Add your desired background color */}
            <PageCard title="Category Edit" to="/categories" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            <PageCard title="Subcategory Edit" to="/subcategories" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            <PageCard title="Privacy Policy Edit" to="/privacy-policy" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            <PageCard title="Refund Policy Edit" to="/refund-policy" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            <PageCard title="Terms of Service Edit" to="/terms-of-service" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ backgroundColor: "#c3854c" }}>
            <PageCard title="Shipping Prices" to="/shipping-prices" />
          </Paper>
        </Grid>
      </Grid>
      <ToastContainer />
    </Container>
  );
};

export default Main;
