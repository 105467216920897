import React, { useContext, useEffect } from "react";
import { Container, Button, TextField, Card, CardContent } from "@mui/material";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MainContext } from "../Context/MainContext";

const HomepageEditor = () => {
  const {
    updateHomepage,
    homepageData,
    setHomepageData,
    getHomepage,
    homepageSliders,
    homepageBanner1,
    homepageBanner2,
    checkToken,
    setHomepageSliders,
  } = useContext(MainContext);

  const handleImageUpload = (event, sectionName, field) => {
    const file = event.target.files[0];

    setHomepageData({
      ...homepageData,
      [sectionName]: { ...homepageData[sectionName], [field]: file },
    });
  };

  // Function to handle changes in text fields
  const handleTextChange = (event, sectionName, field) => {
    const { value } = event.target;
    setHomepageData({
      ...homepageData,
      [sectionName]: {
        ...homepageData[sectionName],
        [field]: value,
      },
    });
  };
  const handleSliderImageUpload = (event) => {
    const files = event.target.files;
    const sliderImages = Array.from(files);
    setHomepageData({
      ...homepageData,
      sliders: homepageData.sliders.concat(sliderImages),
    });
  };

  // Function to save homepage data
  useEffect(() => {
    setHomepageData({
      sliders: [],
      secondBanner: {
        image: "",
        header: "",
        description: "",
      },
      thirdBanner: {
        header: "",
        paragraph: "",
        image: "",
      },
    });
    setHomepageSliders("");

    checkToken();
    getHomepage();
  }, []);

  useEffect(() => {
    setHomepageData({ ...homepageData, sliders: homepageSliders });
  }, [homepageSliders]);

  useEffect(() => {
    setHomepageData({
      ...homepageData,
      thirdBanner: {
        header: homepageData.thirdBanner.header,
        paragraph: homepageData.thirdBanner.paragraph,
        image: homepageBanner2,
      },
      secondBanner: {
        image: homepageBanner1,
        header: homepageData.secondBanner.header,
        description: homepageData.secondBanner.description,
      },
      sliders: homepageSliders,
    });
  }, [homepageBanner2, homepageBanner1, homepageSliders]);

  const saveHomepageData = (e) => {
    e.preventDefault();

    const formData = new FormData();
    homepageData.sliders.forEach((element, index) => {
      formData.append(`sliders`, homepageData.sliders[index]);
    });

    formData.append("secondBanner", homepageData.secondBanner.image);

    formData.append("thirdBanner", homepageData.thirdBanner.image);

    formData.append("secondBannerHeader", homepageData.secondBanner.header);
    formData.append("secondBannerDesc", homepageData.secondBanner.description);
    formData.append("thirdBannerHeader", homepageData.thirdBanner.header);
    formData.append("thirdBannerParagraph", homepageData.thirdBanner.paragraph);
    updateHomepage(formData);
  };

  return (
    <Container>
      <Card>
        <CardContent>
          <h2>Edit Homepage</h2>

          {/* Sliders */}
          <h3>Sliders</h3>
          <input
            type="file"
            accept="image/*"
            multiple // Allow multiple file selection
            onChange={handleSliderImageUpload}
            style={{ display: "none" }} // Hide the input element
            id="slider-image-upload"
          />
          <label htmlFor="slider-image-upload">
            <Button
              variant="outlined"
              component="span"
              onClick={() => {
                setHomepageData({ ...homepageData, sliders: [] });
              }}
            >
              Upload Slider Images
            </Button>
          </label>
          <div className="spaceY" />
          <Carousel showThumbs={false} infiniteLoop>
            {homepageData.sliders.length > 0 ? (
              homepageData.sliders.map((image, index) => (
                <div key={index} style={{ backgroundColor: "black" }}>
                  <img
                    src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}homepage/${image.name}`}
                    alt={`Slider ${index + 1}`}
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </Carousel>

          {/* Second Banner */}
          <h3>Second Banner</h3>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, "secondBanner", "image")}
          />
          {homepageData.secondBanner.image !== "" ? (
            <img
              src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}homepage/${homepageData.secondBanner.image.name}`}
              alt={`SECONDBANNER`}
              style={{
                width: "100px",
                height: "auto",
                backgroundColor: "black",
              }}
            />
          ) : (
            <></>
          )}
          <div className="spaceY" />
          <TextField
            label="Header"
            fullWidth
            variant="outlined"
            value={homepageData.secondBanner.header}
            onChange={(e) => handleTextChange(e, "secondBanner", "header")}
          />
          <div className="spaceY" />
          <TextField
            label="Description"
            fullWidth
            multiline
            variant="outlined"
            value={homepageData.secondBanner.description}
            onChange={(e) => handleTextChange(e, "secondBanner", "description")}
          />

          {/* Third Banner */}
          <h3>Third Banner</h3>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, "thirdBanner", "image")}
          />
          {homepageData.thirdBanner.image !== "" ? (
            <img
              src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}homepage/${homepageData.thirdBanner.image.name}`}
              alt={`SECONDBANNER`}
              style={{
                width: "100px",
                height: "auto",
                backgroundColor: "black",
              }}
            />
          ) : (
            <></>
          )}
          <div className="spaceY" />
          <TextField
            label="Header"
            fullWidth
            variant="outlined"
            value={homepageData.thirdBanner.header}
            onChange={(e) => handleTextChange(e, "thirdBanner", "header")}
          />
          <div className="spaceY" />
          <TextField
            label="Paragraph"
            fullWidth
            multiline
            variant="outlined"
            value={homepageData.thirdBanner.paragraph}
            onChange={(e) => handleTextChange(e, "thirdBanner", "paragraph")}
          />
          <div className="spaceY" />
          {/* Save Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={saveHomepageData}
          >
            Save
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default HomepageEditor;
