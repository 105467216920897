import { Button, Container, List, ListItem, ListItemText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../Context/MainContext";

const CategoryList = () => {
  const navigate = useNavigate();
  const { getCategories, categories, deleteCategories, checkToken } =
    useContext(MainContext);

  useEffect(() => {
    getCategories();
    checkToken();
  }, []);

  const handleDelete = (id) => {
    deleteCategories(id);
  };
  const handleEdit = (id) => {
    navigate(`/categories/${id}`);
  };
  return (
    <>
      {categories ? (
        <Container>
          <h2>Category List</h2>
          <Link to="add-category">Add Category</Link>
          <List>
            {categories.map((category) => (
              <ListItem key={category._id}>
                <img
                  src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${category.image}`}
                  alt="product"
                  style={{ width: "100px" }}
                />{" "}
                <div className="spaceX" />
                <ListItemText primary={category.name} />{" "}
                <div className="spaceX" />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleEdit(category._id)}
                >
                  Edit
                </Button>{" "}
                <div className="spaceX" />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleDelete(category._id)}
                >
                  Delete
                </Button>
              </ListItem>
            ))}
          </List>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default CategoryList;
