import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Homepage from "./pages/Homepage";

import { MainProvider } from "./Context/MainContext";
import Main from "./pages/Main";
import HomepageEditor from "./pages/HomepageEditor";
import ProductList from "./pages/ProductList";
import EditProduct from "./pages/EditProduct";
import CategoryList from "./pages/CategoryList";
import EditCategory from "./pages/EditCategory";
import SubcategoryList from "./pages/SubcategoryList";
import EditSubcategory from "./pages/EditSubcategory";
import PrivacyPolicyUpdate from "./pages/PrivacyPolicyUpdate";
import RefundPolicyUpdate from "./pages/RefundPolicyUpdate";
import TermsOfServiceUpdate from "./pages/TermsOfServiceUpdate";
import ShippingPrices from "./pages/ShippingPrices";
import ShippingPricesEdit from "./pages/ShippingPricesEdit";
import "react-toastify/dist/ReactToastify.css";
import AboutUsEdit from "./pages/AboutUsEdit";
import CreateSubcategory from "./pages/CreateSubCategory";
import CreateCategory from "./pages/CreateCategory";
import AddProduct from "./pages/AddProducts";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <MainProvider children={<Homepage />} />
      </>
    ),
  },
  {
    path: "/main",
    element: (
      <>
        <MainProvider children={<Main />} />
      </>
    ),
  },
  {
    path: "/homepage-editor",
    element: (
      <>
        <MainProvider children={<HomepageEditor />} />
      </>
    ),
  },
  {
    path: "/products",
    element: (
      <>
        <MainProvider children={<ProductList />} />
      </>
    ),
  },
  {
    path: "/products/:productId",
    element: (
      <>
        <MainProvider children={<EditProduct />} />
      </>
    ),
  },
  {
    path: "/categories",
    element: (
      <>
        <MainProvider children={<CategoryList />} />
      </>
    ),
  },
  {
    path: "/categories/:categoryId",
    element: (
      <>
        <MainProvider children={<EditCategory />} />
      </>
    ),
  },
  {
    path: "/subcategories/",
    element: (
      <>
        <MainProvider children={<SubcategoryList />} />
      </>
    ),
  },
  {
    path: "/subcategories/:subcategoryId",
    element: (
      <>
        <MainProvider children={<EditSubcategory />} />
      </>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <>
        <MainProvider children={<PrivacyPolicyUpdate />} />
      </>
    ),
  },
  {
    path: "/refund-policy",
    element: (
      <>
        <MainProvider children={<RefundPolicyUpdate />} />
      </>
    ),
  },
  {
    path: "/terms-of-service",
    element: (
      <>
        <MainProvider children={<TermsOfServiceUpdate />} />
      </>
    ),
  },
  {
    path: "/shipping-prices",
    element: (
      <>
        <MainProvider children={<ShippingPrices />} />
      </>
    ),
  },
  {
    path: "/shipping-prices/:id",
    element: (
      <>
        <MainProvider children={<ShippingPricesEdit />} />
      </>
    ),
  },
  {
    path: "/aboutus-editor",
    element: (
      <>
        <MainProvider children={<AboutUsEdit />} />
      </>
    ),
  },
  {
    path: "subcategories/add-subcategory",
    element: (
      <>
        <MainProvider children={<CreateSubcategory />} />
      </>
    ),
  },
  {
    path: "categories/add-category",
    element: (
      <>
        <MainProvider children={<CreateCategory />} />
      </>
    ),
  },
  {
    path: "categories/:categoryId",
    element: (
      <>
        <MainProvider children={<EditCategory />} />
      </>
    ),
  },
  {
    path: "/products/add-product",
    element: (
      <>
        <MainProvider children={<AddProduct />} />
      </>
    ),
  },
]);
function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
