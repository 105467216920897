import React, { useEffect, useContext } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { MainContext } from "../Context/MainContext";
import { toast } from "react-toastify";

function AboutUsEdit() {
  const {
    aboutUs,
    setAboutUs,
    getAbout,
    updateAbout,
    toastifyData,
    getAboutImage,
    checkToken,
  } = useContext(MainContext);

  useEffect(() => {
    checkToken();
    getAbout();
  }, []);

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("description", aboutUs.description);
    if (aboutUs.image) {
      formData.append("image", aboutUs.image);
    }

    if (aboutUs.description.length < 50) {
      toast.error(
        "Description should have more than 50 characters",
        toastifyData
      );
    } else {
      updateAbout(formData);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAboutUs({ ...aboutUs, image: file });
    }
  };

  return (
    <div>
      <h2>Edit About Us</h2>
      {aboutUs ? (
        <>
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={20}
            value={aboutUs.description ? aboutUs.description : ""}
            onChange={(e) =>
              setAboutUs({ ...aboutUs, description: e.target.value })
            }
          />
          <div className="spaceY" />
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="image-upload"
            onChange={handleImageChange}
          />
          <label htmlFor="image-upload">
            <Button variant="outlined" component="span">
              Upload Image
            </Button>
          </label>
          <div className="spaceY" />
          <Typography variant="body1" gutterBottom>
            {aboutUs.image
              ? `Image: ${
                  aboutUs.image.name ? aboutUs.image.name : aboutUs.image
                }`
              : "No image selected"}
          </Typography>
          <img
            src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${aboutUs.image.name}`}
            alt="about"
            style={{ width: "100px" }}
          />
          <div className="spaceY" />
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AboutUsEdit;
