import {
  Button,
  Container,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainContext } from "../Context/MainContext";
import { toast } from "react-toastify";

// EditSubcategory.js
// ... Import statements

const EditSubcategory = () => {
  const { subcategoryId } = useParams();
  const {
    subcategory,
    getSubcategory,
    setSubcategory,
    updateSubcategory,
    toastifyData,
    checkToken,
    categories,
    getCategories,
  } = useContext(MainContext);

  useEffect(() => {
    checkToken();
    getSubcategory(subcategoryId);
    getCategories();
  }, [subcategoryId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSubcategory({ ...subcategory, [name]: value });
  };

  const handleUpdate = () => {
    const formData = new FormData();
    formData.append("name", subcategory.name);
    formData.append("image", subcategory.image);
    if (subcategory.name.length < 3 && !subcategory.image) {
      toast.error("Name should have more than 3 characters", toastifyData);
      toast.error("Upload Image too", toastifyData);
    } else if (subcategory.name.length < 3) {
      toast.error("Name should have more than 3 characters", toastifyData);
    } else if (!subcategory.image) {
      toast.error("Upload Image too", toastifyData);
    } else {
      updateSubcategory(subcategoryId, formData);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSubcategory({ ...subcategory, image: file });
    }
  };

  return (
    <Container>
      {subcategory ? (
        <>
          {" "}
          <h2>Edit Subcategory</h2>
          <form>
            <img
              src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${subcategory.image.name}`}
              alt="product"
              style={{ width: "100px" }}
            />
            <div className="spaceY" />
            <TextField
              name="name"
              label="Subcategory Name"
              variant="outlined"
              fullWidth
              value={subcategory.name}
              onChange={handleInputChange}
            />
            <div className="spaceY" />
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="image-upload"
              onChange={handleImageChange}
            />
            <label htmlFor="image-upload">
              <Button variant="outlined" component="span">
                Upload Image
              </Button>
            </label>
            <div className="spaceY" />
            <Typography variant="body1" gutterBottom>
              {subcategory.image
                ? `Image: ${
                    subcategory.image.name
                      ? subcategory.image.name
                      : subcategory.image
                  }`
                : "No image selected"}
            </Typography>
            <div className="spaceY" />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                label="Category"
                name="category"
                value={subcategory.category}
                onChange={(e) => {
                  setSubcategory({ ...subcategory, category: e.target.value });
                }}
              >
                {categories.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Update
            </Button>
          </form>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default EditSubcategory;
