import { Button, Container, TextField, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { MainContext } from "../Context/MainContext";

const CreateCategory = () => {
  useEffect(() => {
    checkToken();
  }, []);
  const { toastifyData, createCategories, checkToken } =
    useContext(MainContext);
  const [category, setCategory] = useState({
    name: "",
    image: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCategory({ ...category, [name]: value });
  };

  const handleCreate = () => {
    const formData = new FormData();
    formData.append("name", category.name);

    formData.append("image", category.image);

    if (category.name.length < 3 && !category.image) {
      toast.error("Name should have more than 3 characters", toastifyData);
      toast.error("Upload Image too", toastifyData);
    } else if (category.name.length < 3) {
      toast.error("Name should have more than 3 characters", toastifyData);
    } else if (!category.image) {
      toast.error("Upload Image too", toastifyData);
    } else {
      createCategories(formData);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCategory({ ...category, image: file });
    }
  };
  return (
    <Container>
      <h2>Creat New Category</h2>
      <form>
        <TextField
          name="name"
          label="Category Name"
          variant="outlined"
          fullWidth
          value={category.name}
          onChange={handleInputChange}
        />
        <div className="spaceY" />
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="image-upload"
          onChange={handleImageChange}
        />
        <label htmlFor="image-upload">
          <Button variant="outlined" component="span">
            Upload Image
          </Button>
        </label>
        <div className="spaceY" />
        <Typography variant="body1" gutterBottom>
          {category.image
            ? `Image: ${
                category.image.name ? category.image.name : category.image
              }`
            : "No image selected"}
        </Typography>
        <div className="spaceY" />
        <Button variant="contained" color="primary" onClick={handleCreate}>
          Add
        </Button>
      </form>
    </Container>
  );
};

export default CreateCategory;
